import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const EcosystemPage = () => (
  <Layout>
    <SEO title="Ecosystem" />
    <div className="edge">
        <h1>Ecosystem</h1>
    </div>
      <aside className="edge align-baseline site-margin-vertical">
          <h2>Space Ecosystem</h2>
          <div className="grid medium-grid-2 large-grid-4 block-margin-vertical">
              <div className="trim">
                  <h3>Fonts</h3>
                  <p className="text-preset-default-low">Calculate x-height and cap-height for your font!</p>
                  <a href="https://fonts.spaceframework.dev" className="button button--secondary button--small">Fonts.spaceframework.dev</a>
              </div>

              <div className="trim">
                  <h3>Documentation</h3>
                  <p className="text-preset-default-low">Documentation of the SpaceFramework</p>
                  <a href="https://docs.spaceframework.dev" className="button button--secondary button--small">Docs.spaceframework.dev</a>
              </div>

              <div className="trim">
                  <h3>Github</h3>
                  <p className="text-preset-default-low">Github repository of SpaceFramework</p>
                  <a href="https://github.com/HarwinBorger/SpaceFramework/"
                     className="button button--secondary button--small">Github</a>
              </div>
              <div className="trim">
                  <h3>NPM</h3>
                  <p className="text-preset-default-low">NPM package of SpaceFramework</p>
                  <a href="https://www.npmjs.com/package/spaceframework"
                     className="button button--secondary button--small">NPM</a>
              </div>
          </div>
      </aside>
  </Layout>
)

export default EcosystemPage
